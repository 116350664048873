import React, {useState, useRef} from "react";
import {crud} from "../crudRequests";

export default function PatientInfo(props) {
  const state = props.dashState;
  const p = state.patient;
  const [loading, setLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const dobRef = useRef();
  const genderRef = useRef();
  const heightRef = useRef();
  const cityRef = useRef();
  const emailRef = useRef();
  const weightRef = useRef();
  const provinceRef = useRef();
  const countryRef = useRef();
  const middleNameRef = useRef();
  const preferredNameRef = useRef();
  const preferredPronounRef = useRef();
  const emergencyContactNameRef = useRef();
  const emergencyContactPhoneRef = useRef();
  const emergencyContactRelationRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const postalCodeRef = useRef();
  const phoneCellRef = useRef();
  const phoneAltRef = useRef();
  const homePhoneRef = useRef();
  const emergencyContactEmailRef = useRef();

  async function updatePatientInformation() {
    setLoading(true);
    setUpdateSuccess(false);
    let payload = {
      pid: p["pid"],
      fName: firstNameRef.current.value,
      lName: lastNameRef.current.value,
      dob: dobRef.current.value,
      gender: genderRef.current.value,
      height: heightRef.current.value,
      weight: weightRef.current.value,
      city: cityRef.current.value,
      email: emailRef.current.value,
      phone: phoneCellRef.current?.value,
      province: provinceRef.current.value,
      postalCode: postalCodeRef.current?.value,
      country: countryRef.current.value,
      mName: middleNameRef.current.value,
      preferredName: preferredNameRef.current.value,
      preferredPronoun: preferredPronounRef.current.value,
      emergencyContactName: emergencyContactNameRef.current.value,
      emergencyContactPhone: emergencyContactPhoneRef.current.value,
      emergencyContactRelation: emergencyContactRelationRef.current.value,
      address1: address1Ref.current.value,
      address2: address2Ref.current.value,
      alternativePhone: phoneAltRef.current.value,
      homePhone: homePhoneRef.current.value,
      emergencyContactEmail: emergencyContactEmailRef.current.value,
    };
    crud(state, [
      {
        db: state.db,
        collection: "patients",
        parameters: [{pid: p["pid"]}, {$set: payload}],
        method: "updateOne",
      },
    ])
      .then(() => {
        setLoading(false);
        props.dashDispatch({
          type: "UPDATE_PATIENT",
          pid: p.pid,
          payload,
        });
        setUpdateSuccess(true);
      })
      .catch(function (error) {
        setLoading(false);
        setUpdateError(true);
      });
  }

  return (
    <div className="relative bg-white pt-4 pb-4 text-left align-bottom transition-all transform rounded-lg sm:w-full sm:max-w-lg sm:p-6 sm:align-middle bg-red-500">
      {updateError && (
        <div>
          <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="flex items-center justify-center w-12 bg-red-500">
              <svg
                className="w-6 h-6 mx-2 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
              </svg>
            </div>
            <div className="px-4 py-2 -mx-3">
              <div className="mx-3">
                <span className="font-semibold text-red-500 dark:text-red-400">
                  Error
                </span>
                <p className="text-sm text-gray-600 dark:text-gray-200">...</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {updateSuccess && (
        <div>
          <div className="mb-5 flex w-full max-w-lg overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="flex items-center justify-center w-12 bg-green-500">
              <svg
                className="w-6 h-6 mx-2 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
              </svg>
            </div>
            <div className="px-4 py-2 -mx-3">
              <div className="mx-3">
                <span className="font-semibold text-green-500 dark:text-green-400">
                  Patient Updated
                </span>
                <p className="text-sm text-gray-600 dark:text-gray-200">
                  Patient information has been updated successfully.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between -mb-7">
        <h3
          className="text-3xl font-medium text-gray-800 m-4 mb-6"
          id="modal-title"
        >
          Patient Information
        </h3>
      </div>

      <form
        className="mt-3"
        onSubmit={(e) => {
          e.preventDefault();
          updatePatientInformation();
        }}
      >
        <div className="grid grid-cols-3 gap-3">
          <label className="block mx-4 mt-3" htmlFor="fName">
            <span className="text-[11px] text-off ml-1">First Name*</span>
            <input
              ref={firstNameRef}
              defaultValue={p["fName"]}
              type="text"
              name="fName"
              required
              id="fName"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="mName">
            <span className="text-[11px] text-off ml-1">Middle Name</span>
            <input
              ref={middleNameRef}
              defaultValue={p["mName"]}
              type="text"
              name="mName"
              id="mName"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="lName">
            <span className="text-[11px] text-off ml-1">Last Name*</span>
            <input
              ref={lastNameRef}
              defaultValue={p["lName"]}
              type="text"
              name="lName"
              required
              id="lName"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="preferredName">
            <span className="text-[11px] text-off ml-1">Preferred Name</span>
            <input
              ref={preferredNameRef}
              defaultValue={p["preferredName"]}
              type="text"
              name="preferredName"
              id="preferredName"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="preferredPronoun">
            <span className="text-[11px] text-off ml-1">Preferred Pronoun</span>
            <select
              ref={preferredPronounRef}
              defaultValue={p["preferredPronoun"]}
              name="preferredPronoun"
              id="preferredPronoun"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            >
              <option value="">Select</option>
              <option value="he/him">He/Him</option>
              <option value="she/her">She/Her</option>
              <option value="they/them">They/Them</option>
            </select>
          </label>
          <label className="block mx-4 mt-3" htmlFor="dob">
            <span className="text-[11px] text-off ml-1">Date of Birth</span>
            <input
              ref={dobRef}
              defaultValue={p["dob"]}
              type="date"
              name="dob"
              id="dob"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="gender">
            <span className="text-[11px] text-off ml-1">Gender</span>
            <select
              ref={genderRef}
              defaultValue={p["gender"]}
              name="gender"
              id="gender"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Trans-man">Trans Man</option>
              <option value="Trans-woman">Trans Woman</option>
            </select>
          </label>
          <label className="block mx-4 mt-3" htmlFor="address1">
            <span className="text-[11px] text-off ml-1">Address 1</span>
            <input
              ref={address1Ref}
              defaultValue={p["address1"]}
              type="text"
              name="address1"
              id="address1"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="address2">
            <span className="text-[11px] text-off ml-1">Address 2</span>
            <input
              ref={address2Ref}
              defaultValue={p["address2"]}
              type="text"
              name="address2"
              id="address2"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="city">
            <span className="text-[11px] text-off ml-1">City</span>
            <input
              ref={cityRef}
              defaultValue={p["city"]}
              type="text"
              name="city"
              id="city"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="province">
            <span className="text-[11px] text-off ml-1">Province</span>
            <input
              ref={provinceRef}
              defaultValue={p["province"]}
              type="text"
              name="province"
              id="province"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="postalCode">
            <span className="text-[11px] text-off ml-1">Postal Code</span>
            <input
              ref={postalCodeRef}
              defaultValue={p["postalCode"]}
              type="text"
              name="postalCode"
              id="postalCode"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="country">
            <span className="text-[11px] text-off ml-1">Country</span>
            <input
              ref={countryRef}
              defaultValue={p["country"]}
              type="text"
              name="country"
              id="country"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="email">
            <span className="text-[11px] text-off ml-1">Email</span>
            <input
              ref={emailRef}
              defaultValue={p["email"]}
              type="email"
              name="email"
              id="email"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="phoneCell">
            <span className="text-[11px] text-off ml-1">
              Phone Number (cell)
            </span>
            <input
              ref={phoneCellRef}
              defaultValue={p["phoneCell"]}
              type="text"
              name="phoneCell"
              id="phoneCell"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="phoneAlt">
            <span className="text-[11px] text-off ml-1">
              Phone Number (alternative)
            </span>
            <input
              ref={phoneAltRef}
              defaultValue={p["phoneAlt"]}
              type="text"
              name="phoneAlt"
              id="phoneAlt"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="emergencyContactName">
            <span className="text-[11px] text-off ml-1">
              Emergency Contact Name
            </span>
            <input
              ref={emergencyContactNameRef}
              defaultValue={p["emergencyContactName"]}
              type="text"
              name="emergencyContactName"
              id="emergencyContactName"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="emergencyContactPhone">
            <span className="text-[11px] text-off ml-1">
              Emergency Contact Phone
            </span>
            <input
              ref={emergencyContactPhoneRef}
              defaultValue={p["emergencyContactPhone"]}
              type="text"
              name="emergencyContactPhone"
              id="emergencyContactPhone"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="emergencyContactRelation">
            <span className="text-[11px] text-off ml-1">
              Emergency Contact Relation
            </span>
            <input
              ref={emergencyContactRelationRef}
              defaultValue={p["emergencyContactRelation"]}
              type="text"
              name="emergencyContactRelation"
              id="emergencyContactRelation"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="emergencyContactEmail">
            <span className="text-[11px] text-off ml-1">
              Emergency Contact Email
            </span>
            <input
              ref={emergencyContactEmailRef}
              defaultValue={p["emergencyContactEmail"]}
              type="email"
              name="emergencyContactEmail"
              id="emergencyContactEmail"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-9" htmlFor="height">
            <span className="text-[11px] text-off ml-1">Height (cm)</span>
            <input
              ref={heightRef}
              defaultValue={p["height"]}
              type="text"
              name="height"
              id="height"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="weight">
            <span className="text-[11px] text-off ml-1">Weight (kg)</span>
            <input
              ref={weightRef}
              defaultValue={p["weight"]}
              type="text"
              name="weight"
              id="weight"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
          <label className="block mx-4 mt-3" htmlFor="homePhone">
            <span className="text-[11px] text-off ml-1">Home Phone</span>
            <input
              ref={homePhoneRef}
              defaultValue={p["homePhone"]}
              type="text"
              name="homePhone"
              id="homePhone"
              className="drop-shadow block w-full px-2 py-1.5 text-sm text-off font-medium bg-white border border-gray-200 rounded-lg focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
            />
          </label>
        </div>
        <div className="mt-6 w-full mb-4 mx-4 rounded-lg bg-gray-800 text-white p-1 text-[1rem] hover:bg-[#5754FF]">
          <button
            className="w-full py-2 rounded-lg font-medium flex justify-center items-center focus:outline-none"
            disabled={loading}
          >
            Save Changes
            {loading && (
              <span className="">
                <svg
                  className="animate-spin -mb-0.5 ml-1 -mr-1 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25 stroke-[4px]"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
