import React, {useState, useEffect, useRef, useReducer} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {verifyUser, logoutUser} from "../authRequests";
import {loadDataForPatientLogin} from "../crudRequests";
import Navbar from "../components/Patient.Navbar";
import Notifications from "../components/Patient.Notifications";
import PatientInfo from "../components/Patient.PatientInformation";
import InsuranceInfoForm from "../components/InsuranceInfoForm";
import ChangePassword from "../components/ChangePassword";
import TFA from "../components/TFA";
import LoginHistory from "../components/LoginHistory";
import {patientReducer, init} from "../additional_files/reducer";
import {SocketContext} from "../additional_files/context";
import {useSocket} from "../additional_files/custom";
import CardInfoForm from "../components/CardInfoForm";
import Layout from "../components/Layout";
import StripeCardInfoForm from "../components/StripeCardInfoForm";
import Preferences from "../components/Preference";
export default function User(props) {
  const navigate = useNavigate();
  const [tab, setTab] = useState("prs");
  const loadedRef = useRef(null);
  const {state} = useLocation();
  const [dashState, dispatch] = useReducer(patientReducer, state, init);
  const [prevState, setPrevState] = useState();

  let room = dashState.patient?.pid;

  useEffect(function () {
    verifyUser(state ? state.jwt : "", loadedRef);
  }, []);

  if (prevState !== dashState) {
    setPrevState(dashState);
    navigate("../user", {
      state: {
        ...dashState,
      },
    });
  }

  const socket = useSocket("https://webserver.brightlight.ai/", room, dispatch);
  //const socket = useSocket('http://localhost:3000/', room, dispatch);

  let display = null;

  switch (tab) {
    case "prs":
      display = <PatientInfo dashState={dashState} dashDispatch={dispatch} />;
      break;

    case "sec":
      display = (
        <>
          <ChangePassword dashState={dashState} dashDispatch={dispatch} />
          <TFA dashState={dashState} dashDispatch={dispatch} />
          <Preferences dashState={dashState} dashDispatch={dispatch} />
          <LoginHistory dashState={dashState} dashDispatch={dispatch} />
        </>
      );
      break;

    case "pmt":
      display = (
        <>
          <StripeCardInfoForm dashState={dashState} dashDispatch={dispatch} />
          {/*<InsuranceInfoForm dashState={dashState} dashDispatch={dispatch} />*/}
        </>
      );
      break;
    default:
      display = <PatientInfo dashState={dashState} dashDispatch={dispatch} />;
      break;
  }

  return (
    <>
      <Layout state={{...dashState}}>
        <div ref={loadedRef} style={{opacity: 0}}>
          <div className="relative h-full pb-12 px-10 pt-12">
            <div className="flex space-x-8 mb-5 font-semibold pl-2">
              <button
                className={
                  tab === "prs"
                    ? "bg-gray-800 h-14 w-32 flex justify-center items-center text-white p-1 text-[1rem] rounded-[10px] focus:outline-none"
                    : "bg-white h-14 w-32 flex justify-center items-center text-gray-800 ring-4 ring-inset ring-gray-800 p-1 text-[1rem] rounded-[10px] focus:outline-none"
                }
                onClick={() => {
                  setTab("prs");
                }}
              >
                Personal
              </button>
              <button
                className={
                  tab === "pmt"
                    ? "bg-gray-800 h-14 w-32 flex justify-center items-center text-white p-1 text-[1rem] rounded-[10px] focus:outline-none"
                    : "bg-white h-14 w-32 flex justify-center items-center text-gray-800 ring-4 ring-inset ring-gray-800 p-1 text-[1rem] rounded-[10px] focus:outline-none"
                }
                onClick={() => {
                  setTab("pmt");
                }}
              >
                Payment
              </button>
              <button
                className={
                  tab === "sec"
                    ? "bg-gray-800 h-14 w-32 flex justify-center items-center text-white p-1 text-[1rem] rounded-[10px] focus:outline-none"
                    : "bg-white h-14 w-32 flex justify-center items-center text-gray-800 ring-4 ring-inset ring-gray-800 p-1 text-[1rem] rounded-[10px] focus:outline-none"
                }
                onClick={() => {
                  setTab("sec");
                }}
              >
                Security
              </button>
            </div>
            {dashState.fetching && (
              <div className="h-full w-full  inset-0 bg-white/80 z-[99999] absolute flex justify-center items-center">
                <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-dark"></div>
              </div>
            )}
            <main className="flex  items-start space-y-10 overflow-hidden">
              <section className="p-2 flex justify-start items-start flex-col space-y-14 flex-1">
                {display}
              </section>
            </main>
          </div>
        </div>
      </Layout>
    </>
  );
}
