export function patientReducer(state, action) {
  switch (action.type) {
    case "UPDATE_STATE": {
      return init(action.payload);
    }

    case "UPDATE_PATIENT": {
      let newP = {...state.patient};
      for (let [key, value] of Object.entries(action.payload))
        newP[key] = value;

      if (action.options?.newDoctor) {
        let dids = newP.did;
        dids = [...new Set([...[].concat(dids), action.options?.newDoctor])];
        newP.did = dids;
      }
      return {...state, patient: newP};
    }

    case "NEW_APPOINTMENT": {
      let newP = state.patient;
      if (action.updatePatient) {
        newP = {...newP};
        for (let [key, value] of Object.entries(action.updatePatient))
          newP[key] = value;
      }

      return {
        ...state,
        appointments: state.appointments.concat(action.payload),
        patient: newP,
      };
    }

    case "UPDATE_APPOINTMENT": {
      let appointments = state.appointments;
      let apptIndex = appointments.findIndex(
        (a) => a.aid === action.payload.aid
      );
      let patient = state.patient;

      if (action.changingDoctor) {
        let did = [
          ...new Set([].concat(patient.did).concat(action.payload.did)),
        ];
        patient = {...patient, did};
      }

      return {
        ...state,
        appointments:
          apptIndex !== -1
            ? [
                ...appointments.slice(0, apptIndex),
                action.payload,
                ...appointments.slice(apptIndex + 1),
              ]
            : appointments.concat(action.payload),
        patient,
        selectedAppointment:
          state.selectedAppointment?.aid === action.payload.aid
            ? action.payload
            : state.selectedAppointment,
      };
    }

    case "SELECT_APPOINTMENT": {
      return {...state, selectedAppointment: action.payload};
    }

    case "NEW_NOTIFICATION": {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }

    case "READ_NOTIFICATIONS": {
      return {
        ...state,
        notifications: state.notifications.map((e) => ({...e, read: true})),
      };
    }
    case "CLEAR_NOTIFICATIONS": {
      return {...state, notifications: []};
    }
    case "DELETE_NOTIFICATION": {
      return {
        ...state,
        notifications: state.notifications.filter(
          (e) => e.ntfId !== action.payload.ntfId
        ),
      };
    }
    case "CHANGE_LOCATION": {
      return {...state, selectedLocation: action.payload};
    }
    case "SET_BOOKNOW": {
      return {...state, bookNow: action.payload};
    }
    case "CHANGING_LOCATION_LOADER": {
      return {...state, changingLocation: action.payload};
    }

    default:
      return state;
  }
}

export function init(state) {
  if (!state) return {};

  let doctorsIndexMap = {};
  state.doctors.forEach((d, i) => {
    doctorsIndexMap[d.did] = i;
  });

  let newState = {
    ...state,
    notifications: state.notifications.reverse(),
    doctorsIndexMap,
  };

  return newState;
}
